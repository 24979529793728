/* Apply basic styling to the entire privacy policy page */
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 28px;
    font-weight: bold;
    margin: 20px 0 10px;
}

h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0 10px;
}

h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0 5px;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin: 5px 0;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
}

a {
    text-decoration: underline;
    color: #007bff;
}

/* Add specific styling for links, if needed */

/* Add styling for other HTML elements as needed */

/* Header style */
.header {
    background-color: #007bff;
    color: #fff;
    text-align: center;
    padding: 15px 0;
}

/* Footer style */
.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}
